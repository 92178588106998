import React from "react";

const Banner = () => {
  return (
    <section class="slider_section">
      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="container">
              <div class="row">
                <div class="col-md-9 col-lg-7">
                  <div class="detail-box">
                    <h1>We value your dream to study abroad</h1>
                    <p>
                      At Videsh Vibes Immigration, we provide our clients with
                      accurate and impartial information, ensuring a seamless
                      admission process in the desired Country and University.
                    </p>
                    <div class="btn-box">
                      <a href="#contactus" class="btn-2">
                        Get A Quote
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item ">
            <div class="container">
              <div class="row">
                <div class="col-md-9 col-lg-7">
                  <div class="detail-box">
                    <h1>We Make Visa Process Faster</h1>
                    <p>
                      Our dedicated team continously works behind your visa
                      applications for smooth & error free processing
                    </p>
                    <div class="btn-box">
                      <a href="#contactus" class="btn-2">
                        Get A Quote
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item ">
            <div class="container">
              <div class="row">
                <div class="col-md-9 col-lg-7">
                  <div class="detail-box">
                    <h1>Welcome to Your Gateway to a New Beginning!</h1>
                    <p>
                      Embark on your journey to new horizons with our
                      comprehensive immigration services.
                    </p>
                    <div class="btn-box">
                      <a href="#contactus" class="btn-2">
                        Get A Quote
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container idicator_container">
          <ol class="carousel-indicators">
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          </ol>
        </div>
      </div>
    </section>
  );
};

export default Banner;
