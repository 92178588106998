import React, { useState } from "react";

const AboutUs = () => {
  const [readMore, setReadMore] = useState(false);
  return (
    <section id="aboutus" class="about_section layout_padding">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 px-0">
            <div class="img_container">
              <div class="img-box">
                <img src="images/about-img.jpg" alt="" />
              </div>
            </div>
          </div>
          <div class="col-lg-6 px-0">
            <div class="detail-box">
              <div class="heading_container ">
                <h2>Who Are We?</h2>
              </div>
              <p>
                At Videsh Vibes, we are a dedicated team of immigration and visa
                consultants, as well as experienced language coaches
                specializing in IELTS, PTE, and Duolingo preparation. Our
                mission is to empower individuals and families to achieve their
                international aspirations, whether that involves securing a visa
                for study, work, or travel, or enhancing language proficiency
                for academic and professional success.
              </p>
              <div class="collapse" id="aboutUsCollapse">
                <p>
                  With years of expertise in immigration processes, we provide
                  personalized guidance tailored to your unique circumstances,
                  ensuring a smooth application experience. Our comprehensive
                  coaching programs equip students with the skills and
                  confidence needed to excel in language proficiency tests,
                  opening doors to study abroad opportunities.
                </p>
                <p>
                  We pride ourselves on our commitment to integrity,
                  professionalism, and exceptional service. Whether you’re
                  navigating visa requirements or preparing for a language exam,
                  our knowledgeable team is here to support you every step of
                  the way. Join us in unlocking a world of possibilities and
                  achieving your dreams!
                </p>
              </div>
              <div class="btn-box">
                <a
                  data-toggle="collapse"
                  href="#aboutUsCollapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="aboutUsCollapse"
                  onClick={() => setReadMore(true)}
                >
                  {readMore ? "Read Less" : "Read More"}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
