export const scrollTo = (element, offset = 0) => {
  const ele = document.getElementById(element);
  if (ele) {
    const topPosition =
      ele.getBoundingClientRect().top + window.pageYOffset - offset;
    window.scrollTo({
      top: topPosition,
      behavior: "smooth",
    });
  }
};
