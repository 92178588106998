import React from "react";
import { scrollTo } from "../common";

const Header = () => {
  const onClick = (tab) => {
    scrollTo(tab);
  };

  return (
    <>
      <div class="hero_bg_box">
        <div class="img-box">
          <img src="images/logo1.avif" alt="" />
        </div>
      </div>
      <header class="header_section">
        <div class="header_top">
          <div class="container-fluid">
            <div class="contact_link-container">
              <a href="" class="contact_link1">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
                <span>
                  <b>
                    C-1038, 1st Floor, Central Bazaar, Opp. Varachha Police
                    Station, <br /> Varachha Main Road, Surat - 395006
                  </b>
                </span>
              </a>
              <div>
                <a href="" class="contact_link2">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                  <span>
                    <b>Call : +91 9106390481</b>
                  </span>
                </a>
                <a href="" class="contact_link3">
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                  <span>
                    <b>videshvibesimmigration@gmail.com</b>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="header_bottom">
          <div class="container-fluid">
            <nav class="navbar navbar-expand-lg custom_nav-container">
              <a class="navbar-brand" href="/">
                <img
                  src="/images/white-logo.png"
                  className="header-logo"
                  alt="Logo"
                />
              </a>
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class=""></span>
              </button>

              <div
                class="collapse navbar-collapse ml-auto"
                id="navbarSupportedContent"
              >
                <ul class="navbar-nav">
                  <li class="nav-item active">
                    <a class="nav-link" href="/">
                      Home <span class="sr-only">(current)</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      href="#"
                      onClick={() => onClick("services")}
                    >
                      Services
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      href="#"
                      onClick={() => onClick("aboutus")}
                    >
                      About Us
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      href="#"
                      onClick={() => onClick("countries")}
                    >
                      Countries
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      href="#"
                      onClick={() => onClick("contactus")}
                    >
                      Contact us
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
