import React from "react";

const Footer = () => {
  return (
    <>
      <section class="info_section">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="info_logo">
                <a class="navbar-brand" href="/">
                  <img
                    src="/images/white-logo.png"
                    alt="Logo"
                    width={330}
                    height={80}
                  />
                </a>
                <h4 className="ml-4">Your Dreams, Our Efforts</h4>
              </div>
            </div>
            <div class="col-md-6">
              <div class="info_info">
                <h5>Contact Us</h5>
              </div>
              <div class="info_contact">
                <a href="" class="">
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                  <span>
                    C-1038, 1st Floor, Central Bazaar, Opp. Varachha Police
                    Station, <br /> Varachha Main Road, Surat - 395006
                  </span>
                </a>
                <a href="" class="">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                  <span>Call : +91 9106390481</span>
                </a>
                <a href="" class="">
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                  <span>videshvibesimmigration@gmail.com</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* 
      <footer class="container-fluid footer_section">
        <p>
          &copy; <span id="displayYear"></span> All Rights Reserved By
          <a href="https://html.design/">Free Html Templates</a>
        </p>
      </footer> */}
    </>
  );
};

export default Footer;
