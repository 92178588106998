import React, { useState } from "react";

const servicesData = [
  {
    id: "businessVisaCollapse",
    title: "Business Visa",
    image: "images/s1.png",
    description:
      "Our Business Visa Services are tailored to assist entrepreneurs and professionals in navigating the complexities of obtaining a business visa for various countries. We specialize in providing:",
    details: [
      {
        title: "Consultation",
        content:
          "Personalized guidance on the best visa options for your business needs.",
      },
      {
        title: "Documentation Assistance",
        content:
          "Help with compiling and organizing necessary documents, such as business plans, invitations, and financial statements.",
      },
      {
        title: "Application Support",
        content:
          "Step-by-step assistance throughout the application process to ensure accuracy and completeness. Country-Specific Insights: Expert knowledge of business visa requirements for countries like the USA, Canada, UK, Germany, and Australia.",
      },
    ],
    additionalInfo:
      "With our expertise, you can focus on your business goals while we handle the intricacies of the visa process. Let us help you open doors to new international opportunities!",
  },
  {
    id: "workVisaCollapse",
    title: "Work Visa",
    image: "images/s2.png",
    description:
      "Our Work Visa Services are designed to assist professionals seeking employment opportunities abroad. We provide comprehensive support throughout the work visa application process, including:",
    details: [
      {
        title: "Consultation",
        content:
          "Expert guidance on the best visa options tailored to your profession and destination.",
      },
      {
        title: "Documentation Assistance",
        content:
          "Help with gathering and preparing essential documents, such as job offers, employer sponsorships, and proof of qualifications.",
      },
      {
        title: "Application Support",
        content:
          "Step-by-step assistance to ensure your application is complete and compliant with the specific requirements of your chosen country.",
      },
      {
        title: "Interview Preparation",
        content:
          "Coaching on visa interviews, including tips on how to present your qualifications and intentions effectively.",
      },
    ],
    additionalInfo:
      "With our dedicated services, you can focus on securing your dream job while we handle the complexities of obtaining your work visa. Let us help you take the next step in your international career!",
  },
  {
    id: "touristVisaCollapse",
    title: "Tourist Visa",
    image: "images/s3.png",
    description:
      "Our Tourist Visa Services are designed to help travelers navigate the visa application process for their adventures abroad. We offer comprehensive support to ensure a hassle-free experience, including:",
    details: [
      {
        title: "Consultation",
        content:
          "Personalized advice on the best tourist visa options for your desired international destination.",
      },
      {
        title: "Documentation Assistance",
        content:
          "Guidance on gathering necessary documents, such as travel itineraries, accommodation details, and proof of financial means.",
      },
      {
        title: "Application Support",
        content:
          "Step-by-step help with completing and submitting your visa application to ensure compliance with all requirements.",
      },
      {
        title: "Travel Tips",
        content:
          "Insights on local customs, travel restrictions, and attractions to enhance your travel experience.",
      },
    ],
    additionalInfo:
      "With our expert services, you can focus on planning your trip while we manage the details of your tourist visa application. Let us help you explore the world with confidence!",
  },
  {
    id: "studentVisaCollapse",
    title: "Student Visa",
    image: "images/s4.png",
    description:
      "Our Student Visa Services are designed to support aspiring students in their journey to study abroad. We provide comprehensive assistance to ensure a smooth visa application process, including:",
    details: [
      {
        title: "Consultation",
        content:
          "Personalized guidance on choosing the right study destination and understanding visa options for countries like the USA, Canada, the UK, and Australia.",
      },
      {
        title: "Documentation Assistance",
        content:
          "Help in gathering and preparing essential documents, such as acceptance letters, proof of funds, and health insurance.",
      },
      {
        title: "Application Support",
        content:
          "Step-by-step assistance throughout the application process, ensuring all requirements are met.",
      },
      {
        title: "Interview Preparation",
        content:
          "Coaching on interview techniques and common questions to help you present your case confidently.",
      },
    ],
    additionalInfo:
      "With our dedicated services, you can focus on your education while we help you navigate the complexities of obtaining a student visa. Let us pave the way for your academic journey abroad!",
  },
  {
    id: "coachingCollapse",
    title: "Coaching/Training",
    image: "images/s5.png",
    description:
      "Our IELTS, PTE, and Duolingo Coaching Services are designed to help students and professionals achieve their language proficiency goals with confidence. We offer comprehensive preparation programs that include:",
    details: [
      {
        title: "Personalized Coaching",
        content:
          "Tailored lessons to address your specific strengths and weaknesses, ensuring you receive focused support.",
      },
      {
        title: "Expert Instructors",
        content:
          "Learn from experienced coaches who understand the nuances of each test and can provide valuable insights and strategies.",
      },
      {
        title: "Practice Tests",
        content:
          "Regular mock exams to familiarize you with the test format and build your test-taking skills under timed conditions.",
      },
      {
        title: "Skill Development",
        content:
          "Intensive training in listening, reading, writing, and speaking to enhance your overall language proficiency.",
      },
      {
        title: "Flexible Scheduling",
        content:
          "Convenient class times and formats, including in-person and online options, to fit your busy lifestyle.",
      },
    ],
    additionalInfo:
      "With our dedicated coaching, you’ll gain the skills and confidence needed to excel in your language tests and achieve your academic or professional goals. Let us help you pave the way to success in your international journey!",
  },
  {
    id: "passportCollapse",
    title: "Passport",
    image: "/images/s6.png",
    description:
      "Our Passport Application Services are designed to streamline the process of obtaining or renewing your passport, ensuring a hassle-free experience. We offer a range of services tailored to meet your needs, including:",
    details: [
      {
        title: "Application Guidance",
        content:
          "Expert assistance in completing passport applications accurately, helping you avoid common mistakes and omissions.",
      },
      {
        title: "Document Preparation",
        content:
          "Support in gathering and organizing all necessary documents, such as identification, photos, and proof of citizenship.",
      },
      {
        title: "Review Services",
        content:
          "Thorough review of your application and documents to ensure compliance with all requirements, reducing the risk of delays.",
      },
      {
        title: "Renewal Assistance",
        content:
          "Efficient processing for passport renewals, allowing you to maintain your travel plans without interruption. Expedited Processing: Options for fast-tracking your application, ensuring you receive your passport in time for urgent travel.",
      },
      {
        title: "Travel Information",
        content:
          "Insights on passport validity requirements for various destinations, helping you prepare for your journey. With our comprehensive Passport Application Services, you can navigate the passport process with confidence, getting ready for your next adventure with ease!",
      },
    ],
    additionalInfo:
      "With our dedicated coaching, you’ll gain the skills and confidence needed to excel in your language tests and achieve your academic or professional goals. Let us help you pave the way to success in your international journey!",
  },
  {
    id: "forexCollapse",
    title: "Forex Exchange",
    image: "/images/s7.png",
    description:
      "Our Forex Exchange Services provide individuals and businesses with seamless and reliable currency exchange solutions. We understand the importance of competitive rates and timely transactions in today’s global economy. Our services include:",
    details: [
      {
        title: "Competitive Rates",
        content:
          "Access to favorable exchange rates to maximize your currency value during transactions. Online and In-Person Exchange: Convenient options for currency exchange, whether you prefer to do it online or visit our office.",
      },
      {
        title: "International Transfers",
        content:
          "Efficient processing of international money transfers, ensuring your funds reach their destination quickly and securely.",
      },
      {
        title: "Currency Advisory",
        content:
          "Expert insights on market trends and currency fluctuations to help you make informed exchange decisions.",
      },
      {
        title: "Secure Transactions",
        content:
          "Commitment to safety and security in every exchange, protecting your financial information throughout the process.",
      },
    ],
    additionalInfo:
      "With our Forex Exchange Services, you can confidently manage your currency needs, whether for travel, business, or investment. Let us help you navigate the world of currency exchange with ease!",
  },
];

const Services = () => {
  return (
    <div id="services">
      <section className="service_section layout_padding">
        <div className="container">
          <div className="heading_container heading_center">
            <h2>Our services</h2>
          </div>
          <div className="row justify-content-center">
            {servicesData.map((service, key) => (
              <Card key={key} service={service} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;

const Card = ({ service }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="col-md-6" key={service.id}>
      <div className="box">
        <div className="img-box">
          <img src={service.image} alt={service.title} />
        </div>
        <div className="detail-box">
          <h6>{service.title}</h6>
          <p>{service.description}</p>
          <div className="collapse" id={service.id}>
            <ul>
              {service.details.map((detail, index) => (
                <li key={index}>
                  <b>{detail.title}:</b> {detail.content}
                </li>
              ))}
            </ul>
            <p>{service.additionalInfo}</p>
          </div>
          <a
            data-toggle="collapse"
            href={`#${service.id}`}
            role="button"
            aria-expanded="false"
            aria-controls={service.id}
            onClick={() => setShowMore(true)}
          >
            {showMore ? "Read Less" : "Read More"}
          </a>
        </div>
      </div>
    </div>
  );
};
