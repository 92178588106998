import React from "react";

const Countries = () => {
  return (
    <section id="countries" class="country_section layout_padding">
      <div class="container">
        <div class="heading_container heading_center">
          <h2>Countries we work with</h2>
          <p>Dream destinations of our students</p>
        </div>
        <div class="row">
          <div class="col-md-4">
            <a href="" class="box">
              <img src="/images/countries/usa.jpg" alt="" />
              <div class="detail-box">
                <h3>United States</h3>
              </div>
            </a>
          </div>
          <div class="col-md-4">
            <a href="" class="box">
              <img src="/images/countries/uk.jpg" alt="" />
              <div class="detail-box">
                <h3>United Kingdom</h3>
              </div>
            </a>
          </div>
          <div class="col-md-4">
            <a href="" class="box">
              <img src="/images/countries/canada.jpg" alt="" />
              <div class="detail-box">
                <h3>Canada</h3>
              </div>
            </a>
          </div>
          <div class="col-md-4">
            <a href="" class="box">
              <img src="/images/countries/nz.jpg" alt="" />
              <div class="detail-box">
                <h3>New Zealand</h3>
              </div>
            </a>
          </div>
          <div class="col-md-4">
            <a href="" class="box">
              <img src="/images/countries/aus.jpg" alt="" />
              <div class="detail-box">
                <h3>Australia</h3>
              </div>
            </a>
          </div>
          <div class="col-md-4">
            <a href="" class="box">
              <img src="/images/countries/finland.avif" alt="" />
              <div class="detail-box">
                <h3>Finland</h3>
              </div>
            </a>
          </div>
          <div class="col-md-4">
            <a href="" class="box">
              <img src="/images/countries/sw.jpg" alt="" />
              <div class="detail-box">
                <h3>Switzerland</h3>
              </div>
            </a>
          </div>
          <div class="col-md-4">
            <a href="" class="box">
              <img src="/images/countries/germany.jpg" alt="" />
              <div class="detail-box">
                <h3>Germany</h3>
              </div>
            </a>
          </div>
          <div class="col-md-4">
            <a href="" class="box">
              <img src="/images/countries/france.jpg" alt="" />
              <div class="detail-box">
                <h3>France</h3>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Countries;
